.footWrap{
    width: 100%;
    color: #fff;
    background: url('../../img/foot.png') no-repeat;
    -moz-background-size:100% 100%;
    background-size:100% 100%;
    // 遮罩层及整体
    .footBox{
        height: 100%;
        background: rgba(0, 0, 0, .8);
        padding: 49px 50px 40px 50px;
        box-sizing: border-box;
        overflow: hidden;
        // 联系我们/关于我们
        .footView{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            position: relative;
            margin-bottom: 50px;
            // 二维码
            .codeIcon{
              width: 120px;
              height: 120px;  
              position: absolute;
              bottom: -92px;
              right: 120px;
            }
            .leftDiv{
              height: 100%;
              width: 60%;
              display: flex;
              justify-content: space-around;
              p{
                cursor: pointer
              }
            }
            // 联系我们/关于我们
            .footDiv{
                width: 38%;
                right: 0px;
                .footTitle{
                  width: 116px;
                  margin-bottom: 20px;
                }
                
                // 名称
                .title{
                    line-height: 26px;
                    font-size: 14px;
                }
                // 列表
                .leftBox{
                    display: flex;
                    margin-bottom:28px;
                    .icon{
                        vertical-align: top;
                        width: 24px;
                        height: 24px;
                    }
                    .text{
                        margin:0 0 0 10px;
                    }
                }
            }
        }
        // 版权
        .copyright{
            text-align: center;
        }
    }
}

