.groupWrap{
    width: 100%;
    text-align: center;
    margin-top: 32px;
    .groupTitle{
        font-size: 30px;
        color: #0052D9;
        font-weight: bold;
    }
    .listBox{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        margin: 30px auto 0px;
        .listDiv{
            width: 190px;
            height: 212px;
            position: relative;
            .listImg{
              width: 100%;
            }
            .listView{
              position: absolute;
              bottom: 0px;
                width: 100%;
                height: 54px;
                background: rgba(39, 39, 39, .7);
                color: #fff;
                text-align: center;
                .listName{
                    font-size: 16px;
                    padding-top: 4px;
                }
                .listTitle{
                    font-size: 14px;
                }
            }
        }
    }
}