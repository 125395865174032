.topBox {
  position: relative;
  // height: 670px;
  // 轮播
  .sipWrap {
    .swiperBox {
      min-width: 1200px;
      width: 100%;
      height: 600px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .sipDiv {
      position: relative;
      top: 304px;
      width: 1200px;
      margin: auto;
      color: #ffffff;
      z-index: 9999;
      .sipDescribe {
        max-width: 400px;
        text-align: center;
        .sipText {
          font-size: 36px;
        }
        .English {
          font-size: 14px;
        }
        .sipBtn {
          width: 140px;
          height: 40px;
          background: #0052d9;
          border-radius: 6px;
          line-height: 40px;
          font-size: 14px;
          font-weight: 400;
          margin: 32px auto;
          cursor: pointer;
          text-align: center;
        }
      }
    }
    .sipBox {
      width: 100vh;
      height: 600px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: #9e9e9e;
      .sipImg {
        width: 100%;
        // height: 100%;
      }
      .sipView {
        display: flex;
        justify-content: center;
      }
    }
  }
  // 遮罩层及顶部Tab栏
  .topDiv {
    width: 100%;
    position: absolute;
    top: 0;
    font-size: 14px;
    // 登录/注册
    .topView {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      margin-top: 12px;
      padding: 12px 0;
      border-bottom: 1px solid #fff;
      height: 48px;
      // 左边
      .leftBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .leftDiv {
          display: flex;
          .topIcon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
      }
      // 右边
      .rightBox {
        display: flex;
        div {
          width: 74px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
    // Tab栏
    .tabBox {
      width: 1200px;
      height: 40px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      .logo {
        width: 116px;
        height: 40px;
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent !important;
      }
    }
    // 登录/注册
    .loginBox {
      width: 1200px;
      position: relative;
      // 表单外包围
      .login {
        width: 360px;
        background: #ffffff;
        border-radius: 6px;
        position: absolute;
        right: 0;
        text-align: center;
        padding: 70px;
        box-sizing: border-box;
        // logo
        .logo {
          width: 220px;
          height: 76px;
        }
        // 表单
        .loginForm {
          margin-top: 40px;
          // 忘记密码
          .forget {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            text-align: right;
            cursor: pointer;
          }
          // 更改input高度
          #userInp {
            height: 30px;
          }
          #passInp {
            height: 30px;
          }
        }
      }
    }
  }
}
