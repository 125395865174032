.coreWrap{
    width: 100%;
    text-align: center;
    background: #F5F5F5;
    margin-top: 32px;
    overflow: auto;
    .coreTitle{
        margin: 32px auto 0px ;
        font-size: 30px;
        color: #0052D9;
        font-weight: bold;
    }
    .coreList{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        flex-wrap: wrap;
        .coreBox{
            width: 290px;
            height: 384px;
            background-size: 100% 100%;
            border-radius: 4px;
            position: relative;
            overflow: hidden;
            margin: 30px 0px;
            .coreImg {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }
            .coreDiv{
                width: 100%;
                height: 140px;
                background:rgba(0, 0, 0, .3);
                padding: 16px 0 0 24px;
                box-sizing: border-box;
                text-align: left;
                position: absolute;
                .coreName{
                    font-size: 28px;
                    color: #FFFFFF;
                }
                .coreText{
                    font-size: 16px;
                    color: #FFFFFF;
                    margin: 0px 0 12px;
                }
                .coreBtn{
                    width: 96px;
                    height: 28px;
                    background: #0052D9;
                    border-radius: 6px;
                    text-align: center;
                    line-height: 28px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
        }
    }
}