.serveWrap{
    width: 100%;
    height: 330px;
    background: url('../../../img/serve.png') no-repeat;
    background-size: cover;
    position: relative;
    // .serveBox{
    //     position: absolute;
    //     top: 194px;
    //     left: 432px;
    //     .serveTitle{
    //         font-size: 30px;
    //         font-weight: 400;
    //         line-height: 30px;
    //         color: #FFFFFF;
    //     }
    //     .serveText{
    //         margin-top: 40px;
    //         font-size: 18px;
    //         line-height: 56px;
    //         color: #FFFFFF;
    //     }
    // }
    .serveDiv{
        width: 1000px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .serveList{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .listBox{
                text-align: center;
                .listTitle{
                    font-size: 50px;
                    color: #FFFFFF;
                }
                .listName{
                    font-size: 30px;
                    color: #FFFFFF;
                }
            }
        }
    }
}