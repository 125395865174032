.tabWrap{
    width: 100%;
    height: 56px;
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
    line-height: 56px;
    .tabBox{
        width: 1200px;
        display: flex;
        justify-content: space-around;
        .tabDiv{
            flex: 1;
            cursor: pointer;
            position: relative;
            text-align: center;
        }
        .activity{
            width: 100%;
            border-bottom: 2px solid #2081FF;
            position: absolute;
            bottom: 0;
        }
    }
}