.targetWrap{
    width: 100%;
    text-align: center;
    padding: 40px 0px 30px;
    .textBox{
      margin: 0 auto;
      width: 1200px;
      background: url('../../../img/corevalue.png') no-repeat right;
      overflow: hidden;
      .text{
        margin-top: 60px;
        width: 70%;
        text-align: left;
        overflow: hidden;
        .texttitle {
          font-size: 30px;
          color: #0052D9;
          text-align: center;
          font-weight: bold;
        }
        .head {
          display: flex;
          justify-self: start;
          margin-top: 27px;
          .headcontent{
            margin-left: 16px;
            color: #333;
            img{
              width: 20px;
              height: 20px;
              margin-bottom: 6px;
            }
            strong{
              font-size: 18px;
              margin-left: 4px;
              color: #0052D9;
            }
            span{
              font-size: 14px;
            }
          }
          .headcontent:first-child{
            margin-left: 0px;
          }
        }
        .content{
          font-size: 18px;
          margin-top: 20px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 38px;
          color: #333333;
          margin-bottom: 60px;
        }
      }
    }
}