.newsWrap{
    width: 100%;
    background: #F5F5F5;
    margin: 20px 0;
    text-align: center;
    padding-bottom: 16px;
    overflow: hidden;
    .newsTitle{
        font-size: 30px;
        color: #0052D9;
        margin: 30px 0px;
        font-weight: bold;
    } 
    .listBox{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        margin: 12px auto 0;
        .listLeft{
            width: 590px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .leftBox{
                width: 290px;
                height: 274px;
                margin-top: 10px;
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
                position: relative;
                .newsImg{
                    width: 100%;
                    height: 218px;
                }
                .newsText{
                  position: absolute;
                  bottom: 0px;
                    width: 100%;
                    height: 56px;
                    padding: 8px 16px;
                    font-size: 14px;
                    line-height: 22px;
                    color: #FFFFFF;                  
                    background: rgba(39,39,39,.7);
                    text-align: left;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }
        }
        .listRight{
            width: 592px;
            .newList{
              height: 520px;
              overflow: auto;
            }
            .rightTitle{
                width: 100%;
                height: 40px;
                font-size: 24px;
                color: #333333;
                border-bottom: 1px solid #999999;
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
                cursor: pointer;
            }
            .rightBox{
                width: 540px;
                display: flex;
                margin-bottom: 40px;
                cursor: pointer;
                .newsDate{
                    width: 70px;
                    height: 70px;
                    background: rgba(39,39,39,.7);
                    font-weight: 600;
                    color: #FFFFFF;
                    .newsDay{
                        font-size: 24px;
                        margin-top: 4px;
                    }
                    .newsYears{
                        font-size: 14px;
                    }
                }
                .rightDiv{
                    width: 460px;
                    margin-left: 8px;
                    text-align: left;
                    .rightLabel{
                        font-size: 16px;
                        color: #333333;
                        height: 26px;
                        overflow: hidden;
                        text-overflow:ellipsis; 
                        white-space: nowrap;
                    }
                    .rightText{
                        font-size: 14px;
                        color: #999999;
                        height: 44px;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}